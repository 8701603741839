.navbar{
  background: #0b1237 !important;
}
@media screen and ( max-width: 991.7px){
  .desktop{
    display: none !important;
  }
 }
 @media screen and (min-width: 992px){

  .mobile{
    display: none !important;
  }
}
@media screen and (width: 320px) and ( max-width: 360px){
  .main_logo{
    height: 55px !important;
    width: 130px !important;
  }
  .brandTagLine{
    margin-left: -110px!important;
  }
}
@media screen and (max-width: 576px){
  .main_logo{
    height: 47px !important;
    width: 153px !important;
  }
}
@media screen and (width: 576.1px) and ( max-width: 767.7px){
  .main_logo{
    height: 55px !important;
    width: 165px !important;
  }
}
@media screen and (width: 768px) and ( max-width: 991.7px){
  .main_logo{
    height: 60px !important;
    width: 165px !important;
  }
}

@media screen and (min-width: 992px) and ( max-width: 1199.7px){
  .main_logo{
    height: 46.66px !important;
    width: 140px !important;
  }
}
@media screen and (min-width: 1200px){
  .main_logo{
    height: 60px !important;
    width: 210px !important
  }
  .brandTagLine{
    margin-left: -138px!important;
    margin-top: 43px!important;
  }
}