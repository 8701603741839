@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("https://d2b7dijo04ypct.cloudfront.net/events-templates/fonts/poppins-regular.woff2") format("woff2");
  font-display: swap;
}
* {
  font-family: "PoppinsRegular" !important;
}

 
